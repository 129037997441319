import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { colors } from "components/Theme/WebTheme.js";
import { Typography } from "@mui/material";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermCondition(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/header-back.jpg").default}
      />
        <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
                <br />
                <Typography
                    variant="h4"
                    className={classes.ltrDescription}
                    style={{
                    textAlign: "left",
                    marginTop: "30px",
                    minHeight: "32px",
                    color: colors.Header,
                    wordBreak: "break-word",
                    }}
                >
                    Terms & Conditions
                </Typography>
                <Typography className={classes.ltrDescription} my={2}>
                    Welcome to Niljyoti. By using our website and services, you agree to
                    the following terms and conditions. Please read them carefully.
                </Typography>
                <ul
                    className={classes.ltrDescription}
                    style={{ listStyleType: "none" }}
                >
                    <li>
                        <Typography variant="h6" mb={2}>
                            1. Acceptance of Terms:
                        </Typography>
                        <Typography className={classes.ltrDescription} my={2}>
                            By accessing or using our services, you agree to comply with and
                            be bound by these Terms and Conditions and our Privacy Policy.
                            If you do not agree with these terms, please do not use or
                            access our service.
                        </Typography>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"} mb={2}>
                            2. Services :
                        </Typography>
                        <Typography className={classes.ltrDescription} my={2}>
                            Niljyoti Travels provides travel and car rental services. The
                            specifics of the services, including pricing and availability,
                            are subject to change without notice.
                        </Typography>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"} mb={2}>
                            3. Booking and Payments:
                        </Typography>
                        <ul
                            className={classes.ltrDescription}
                            style={{ listStyleType: "disc" }}
                        >
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Reservations:
                                </Typography>
                                <Typography my={2}>
                                    All reservations for car rentals must be made through our
                                    application or customer service.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Payment:
                                </Typography>
                                <Typography my={2}>
                                    Full payment is required at the time of booking unless
                                    otherwise specified. Payments can be made online and
                                    offline.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Cancellations and Refunds:
                                </Typography>
                                <Typography my={2}>
                                    There is also a refund policy for cancellations made before
                                    the scheduled rental date.
                                </Typography>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"} mb={2}>
                            4. Rental Terms:
                        </Typography>
                        <ul
                            className={classes.ltrDescription}
                            style={{ listStyleType: "disc" }}
                        >
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Eligibility:
                                </Typography>
                                <Typography my={2}>
                                    Renters must possess a valid driver’s license.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Insurance:
                                </Typography>
                                <Typography my={2}>
                                    The renter is responsible for any damages or liabilities
                                    incurred during the rental period unless covered by
                                    insurance.
                                </Typography>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"} mb={2}>
                            5. User Responsibilities:
                        </Typography>
                        <ul
                            className={classes.ltrDescription}
                            style={{ listStyleType: "disc" }}
                        >
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Accuracy of Information:
                                </Typography>
                                <Typography my={2}>
                                    You agree to provide accurate and complete information when
                                    making a booking.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Conduct:
                                </Typography>
                                <Typography my={2}>
                                    You agree to use our application and services in compliance
                                    with applicable laws and not to engage in any unlawful or
                                    prohibited activities.
                                </Typography>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"}>
                            6. Limitation of Liability:
                        </Typography>
                        <Typography my={2}>
                            Niljyoti shall not be liable for any direct, indirect,
                            incidental, or consequential damages arising from the use of our
                            services or website. We are not responsible for any loss or
                            damage that may result from the use of rented vehicles.
                        </Typography>
                    </li>

                    <li>
                        <Typography variant="h6" fontWeight={"bold"}>
                            7. Changes to Terms :
                        </Typography>
                        <Typography my={2}>
                            We reserve the right to modify these Terms and Conditions at any
                            time. Changes will be effective immediately upon posting on our
                            website. Your continued use of our services constitutes your
                            acceptance of the updated terms.
                        </Typography>
                    </li>

                    {/* <li>
                        <Typography variant="h6" fontWeight={"bold"}>
                            8. Governing Law :
                        </Typography>
                    </li> */}
                    <li>
                        <Typography variant="h6" fontWeight={"bold"}>
                            8. Contact Information:
                        </Typography>
                        <Typography my={2}>
                            For any questions or concerns regarding these Terms and
                            Conditions, please contact us at 9954926020.
                        </Typography>
                    </li>
                </ul>
                <br />
                <br />
            </div>
        </div>
        <Footer />
    </div>
  );
}
