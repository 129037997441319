export const FirebaseConfig = {
	"projectId": "ngt-project-e45a3",
	"appId": "1:181881850476:web:cfaf2d190c36f397952649",
	"databaseURL": "https://ngt-project-e45a3-default-rtdb.firebaseio.com",
	"storageBucket": "ngt-project-e45a3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAo7wGzc4YzkP4zgKWGE_scMXoBNISXOAQ",
	"authDomain": "ngt-project-e45a3.firebaseapp.com",
	"messagingSenderId": "181881850476",
	"measurementId": "G-Q3E523GWFS"
};