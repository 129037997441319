import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { colors } from "components/Theme/WebTheme.js";
import { Typography } from "@mui/material";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/header-back.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <Typography
            variant="h4"
            className={classes.ltrDescription}
            style={{
              textAlign: "left",
              marginTop: "30px",
              minHeight: "32px",
              color: colors.Header,
              wordBreak: "break-word",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography className={classes.ltrDescription} my={2}>
            Welcome to Niljyoti. We are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, and safeguard your information
            when you visit our website, make a booking, or use our services.
          </Typography>
          <ul
            className={classes.ltrDescription}
            style={{ listStyleType: "none" }}
          >
            <li>
              <Typography variant="h6" mb={2}>
                1. Information We Collect:
              </Typography>
              <ul
                className={classes.ltrDescription}
                style={{ listStyleType: "disc" }}
              >
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Personal Information:
                  </Typography>
                  <Typography my={2}>
                    When you make a booking or register on our site, we collect
                    personal information including, but not limited to, your
                    name, email address, phone number, address, and payment
                    information.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Usage Data:
                  </Typography>
                  <Typography my={2}>
                    We collect information about your interactions with our
                    website, including IP addresses, browser types, operating
                    systems, pages visited, and the date and time of your
                    visits.
                  </Typography>
                </li>
              </ul>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"} mb={2}>
                2. How We Use Your Information:
              </Typography>
              <Typography variant="body1" fontWeight={"bold"} mb={2} ml={3}>
                We use your information for the following purposes:
              </Typography>
              <ul
                className={classes.ltrDescription}
                style={{ listStyleType: "disc" }}
              >
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    To Process Transactions:
                  </Typography>
                  <Typography my={2}>
                    To process and manage bookings, payments, and reservations.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    To Communicate with You:
                  </Typography>
                  <Typography my={2}>
                    To send booking confirmations, updates, promotional offers,
                    and customer service communications.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    To Improve Our Services:
                  </Typography>
                  <Typography my={2}>
                    To analyze usage patterns and improve our website and
                    services based on user feedback.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    To Comply with Legal Obligations:
                  </Typography>
                  <Typography my={2}>
                    To fulfill legal and regulatory requirements.
                  </Typography>
                </li>
              </ul>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"} mb={2}>
                3. How We Share Your Information:
              </Typography>
              <Typography variant="body1" fontWeight={"bold"} mb={2} ml={3}>
                We may share your information with:
              </Typography>
              <ul
                className={classes.ltrDescription}
                style={{ listStyleType: "disc" }}
              >
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Service Providers:
                  </Typography>
                  <Typography my={2}>
                    Third-party service providers who assist us in operating our
                    website, processing payments, and delivering services. These
                    providers are contractually obligated to protect your
                    information and use it only for the purposes for which it
                    was provided.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Legal Requirements:
                  </Typography>
                  <Typography my={2}>
                    Authorities or legal entities when required to comply with
                    legal obligations, enforce our Terms and Conditions, or
                    protect our rights, property, or safety.
                  </Typography>
                </li>
              </ul>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"}>
                4. Data Security:
              </Typography>
              <Typography my={2}>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction. However, no data transmission over
                the internet or electronic storage can be guaranteed to be
                completely secure.
              </Typography>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"}mb={2}>
                5. Your Rights:
              </Typography>
              <ul
                className={classes.ltrDescription}
                style={{ listStyleType: "disc" }}
              >
                <li>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Access and Correction:
                  </Typography>
                  <Typography my={2}>
                    You have the right to access and correct your personal
                    information. You can request updates or deletions by
                    contacting us at 9954926020.
                  </Typography>
                </li>
              </ul>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"}>
                6. Children's Privacy:
              </Typography>
              <Typography my={2}>
                Our services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                children under 18.
              </Typography>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"}>
                7. Changes to This Privacy Policy:
              </Typography>
              <Typography my={2}>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated effective date.
              </Typography>
            </li>

            <li>
              <Typography variant="h6" fontWeight={"bold"}>
                8. Contact Us:
              </Typography>
              <Typography my={2}>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at : 9954926020.
              </Typography>
            </li>
          </ul>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
