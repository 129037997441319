import {
  FETCH_CARS,
  FETCH_CARS_SUCCESS,
  FETCH_CARS_FAILED,
  EDIT_CAR
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set, child, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchCars = () => (dispatch) => {

  const {
    carsRef
  } = firebase;

  dispatch({
    type: FETCH_CARS,
    payload: null
  });

  const userInfo = store.getState().auth.profile;

  onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i]
      });
      dispatch({
        type: FETCH_CARS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_CARS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_cars
      });
    }
  });
};

export const editCar = (car, method) => async (dispatch) => {
  const {
    singleUserRef,
    carAddRef,
    carEditRef,
    carImage,
  } = firebase;

  dispatch({
    type: EDIT_CAR,
    payload: { method, car },
  });

  if (method === 'Add') {
    push(carAddRef, car);
  } else if (method === 'Delete') {
    remove(carEditRef(car.id));
  } else if (method === 'UpdateImage') {
    const imageUploadPromises = [];
    if (car.car_image) {
      imageUploadPromises.push(
        uploadBytesResumable(carImage(car.id), car.car_image)
          .then(() => getDownloadURL(carImage(car.id)))
          .then((url) => {
            set(carEditRef(car.id + '/car_image'), url); // Only update car_image field
          })
      );
    }

    if (car.rc_image) {
      imageUploadPromises.push(
        uploadBytesResumable(carImage('rc_' + car.id), car.rc_image)
          .then(() => getDownloadURL(carImage('rc_' + car.id)))
          .then((url) => {
            set(carEditRef(car.id + '/rc_image'), url); // Only update rc_image field
          })
      );
    }

    if (car.insurance_image) {
      imageUploadPromises.push(
        uploadBytesResumable(carImage('insurance_' + car.id), car.insurance_image)
          .then(() => getDownloadURL(carImage('insurance_' + car.id)))
          .then((url) => {
            set(carEditRef(car.id + '/insurance_image'), url); // Only update insurance_image field
          })
      );
    }

    if (car.pollution_image) {
      imageUploadPromises.push(
        uploadBytesResumable(carImage('pollution_' + car.id), car.pollution_image)
          .then(() => getDownloadURL(carImage('pollution_' + car.id)))
          .then((url) => {
            set(carEditRef(car.id + '/pollution_image'), url); // Only update pollution_image field
          })
      );
    }

    await Promise.all(imageUploadPromises); // Wait for all the updates
  } else {
    set(carEditRef(car.id), car);
  }
};

export const updateUserCarWithImage = (newData, blob, rcBlob, insuranceBlob, pollutionBlob) => (dispatch) => {
  const {
    auth,
    carAddRef,
    singleUserRef,
    carImage,
  } = firebase;

  var carId = push(carAddRef).key;

  const uploadPromises = [];

  // Upload the main car image
  if (blob) {
    uploadPromises.push(uploadBytesResumable(carImage(carId), blob)
      .then(() => {
        blob.close();
        return getDownloadURL(carImage(carId));
      })
      .then((url) => {
        newData.car_image = url;
      }));
  }

  // Upload RC image
  if (rcBlob) {
    uploadPromises.push(uploadBytesResumable(carImage('rc_' + carId), rcBlob)
      .then(() => {
        rcBlob.close();
        return getDownloadURL(carImage('rc_' + carId));
      })
      .then((url) => {
        newData.rc_image = url;
      }));
  }

  // Upload insurance image
  if (insuranceBlob) {
    uploadPromises.push(uploadBytesResumable(carImage('insurance_' + carId), insuranceBlob)
      .then(() => {
        insuranceBlob.close();
        return getDownloadURL(carImage('insurance_' + carId));
      })
      .then((url) => {
        newData.insurance_image = url;
      }));
  }

  // Upload pollution image
  if (pollutionBlob) {
    uploadPromises.push(uploadBytesResumable(carImage('pollution_' + carId), pollutionBlob)
      .then(() => {
        pollutionBlob.close();
        return getDownloadURL(carImage('pollution_' + carId));
      })
      .then((url) => {
        newData.pollution_image = url;
      }));
  }

  // After all image uploads are complete, save the data to the database
  Promise.all(uploadPromises)
    .then(() => {
      set(child(carAddRef, carId), newData);
      if (newData.active) {
        let updateData = {
          carType: newData.carType,
          vehicleNumber: newData.vehicleNumber,
          vehicleMake: newData.vehicleMake,
          vehicleModel: newData.vehicleModel,
          other_info: newData.other_info,
          car_image: newData.car_image,
          rc_image: newData.rc_image,
          insurance_image: newData.insurance_image,
          pollution_image: newData.pollution_image,
          carApproved: newData.approved,
          updateAt: new Date().getTime(),
        };
        update(singleUserRef(auth.currentUser.uid), updateData);
      }
    })
    .catch((error) => {
      console.error(error);
      Alert.alert(t('alert'), t('image_upload_error'));
    });
};
